<template>
  <article class="slider-teaser">
    <div class="slider-teaser__header">
      <SpunqImage
        v-if="article.attributes.teaser.image"
        class="slider-teaser__picture"
        :image="article.attributes.teaser.image"
        :width="310"
        :height="207"
        :mobile-width="100"
        :mobile-height="70"
        mode="crop"
        :lazy="lazy"
      />
      <h2 v-if="article.attributes.teaser.title" class="slider-teaser__title">
        <ArticleLink v-snip="3" :article="article" :title="article.attributes.teaser.title" class="slider-teaser__link">
          {{ article.attributes.teaser.title }}
        </ArticleLink>
      </h2>
    </div>
    <footer class="slider-teaser__footer">
      <span class="slider-teaser__date">
        {{ article.attributes.frontendDate | formatDate($config) }}
      </span>
    </footer>
  </article>
</template>

<script>
import SpunqImage from '@/components/ui/image/spunq-image'
import ArticleLink from '@/components/article/link'

export default {
  components: {
    SpunqImage,
    ArticleLink,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';
.slider-teaser {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  @media all and (min-width: $screen-width-767) {
    max-width: 200px;
  }

  &__header {
    width: 100%;
  }

  &:hover {
    .slider-teaser__picture {
      img {
        transform: scale(1.01);
      }
    }
  }

  &__title {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 10px;

    @media all and (min-width: $screen-width-767) {
      font-size: 20px;
    }
  }
  &__link {
    text-align: left;
    color: $color-text-dark-gray;
    transition: color 0.7s;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  &__picture {
    max-width: 100px;
    margin-left: 10px;
    float: right;
    transition: transform 0.7s;

    @media all and (min-width: $screen-width-767) {
      overflow: hidden;
      max-width: none;
      width: 100%;
      margin: 0;
    }

    img {
      transform: scale(1);
    }
  }

  &__footer {
    padding-top: 10px;
    clear: right;
    font-size: 13px;
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    display: flex;
    justify-content: space-between;

    @media all and (min-width: $screen-width-767) {
      font-size: 12px;
    }

    &--noimage {
      justify-content: flex-end;
    }
  }
  &__date {
    color: $color-text;
  }
}
.darkmode {
  .slider-teaser {
    color: $color-white;

    .slider-teaser__link {
      color: $color-white;
    }

    .slider-teaser__date {
      color: $color-white;
      opacity: 0.6;
    }
  }
}
</style>
