var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"background-teaser"},[_c('div',{staticClass:"background-teaser-box"},[(_vm.article.attributes.teaser.image)?_c('SpunqImage',{staticClass:"background-teaser__image",attrs:{"image":_vm.article.attributes.teaser.image,"width":570,"height":570,"mobile-width":374,"mobile-height":374,"mode":"crop","lazy":true}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"background-teaser__content"},[(_vm.article.attributes.teaser.preTitle)?_c('p',{staticClass:"background-teaser__category"},[_vm._v("\n        "+_vm._s(_vm.article.attributes.teaser.preTitle)+"\n      ")]):(_vm.article.relationships.channels)?_c('p',{staticClass:"background-teaser__category"},[_vm._v("\n        "+_vm._s(_vm._f("getChannelName")(_vm.article.relationships.channels,_vm.$config))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.article.attributes.teaser.title)?_c('SubHeadline',{staticClass:"background-teaser__headline",attrs:{"tag":"h2"}},[_c('ArticleLink',{directives:[{name:"snip",rawName:"v-snip",value:(4),expression:"4"}],staticClass:"background-teaser__link",attrs:{"article":_vm.article,"title":_vm.article.attributes.teaser.title}},[_vm._v("\n          "+_vm._s(_vm.article.attributes.teaser.title)+"\n        ")])],1):_vm._e(),_vm._v(" "),_c('footer',{staticClass:"background-teaser__footer"},[(_vm.article.relationships.authors.length > 0)?_vm._l((_vm.article.relationships.authors),function(author,index){return _c('span',{key:index,staticClass:"background-teaser__authors"},[_vm._v("\n            "+_vm._s(author.attributes.firstName)+" "+_vm._s(author.attributes.lastName)+"\n            "),(_vm.article.relationships.authors.length === 2 && index + 1 !== _vm.article.relationships.authors.length)?_c('span',{key:index},[_vm._v("\n               und \n            ")]):_vm._e(),_vm._v(" "),(
                _vm.article.relationships.authors.length > 2 && index + 1 === _vm.article.relationships.authors.length - 1
              )?_c('span',{key:index},[_vm._v("\n               und \n            ")]):_vm._e(),_vm._v(" "),(
                _vm.article.relationships.authors.length > 2 &&
                index + 1 !== _vm.article.relationships.authors.length &&
                index + 1 !== _vm.article.relationships.authors.length - 1
              )?_c('span',{key:index},[_vm._v("\n              , \n            ")]):_vm._e()])}):_vm._e(),_vm._v(" "),(_vm.article.relationships.authors.length > 0 && _vm.article.attributes.frontendDate)?[_vm._v(" ﹒ ")]:_vm._e(),_vm._v(" "),(_vm.article.attributes.frontendDate)?[_vm._v("\n          "+_vm._s(_vm._f("formatDate")(_vm.article.attributes.frontendDate,_vm.$config))+"\n        ")]:_vm._e()],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }