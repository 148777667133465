<template>
  <article class="background-teaser">
    <div class="background-teaser-box">
      <SpunqImage
        v-if="article.attributes.teaser.image"
        :image="article.attributes.teaser.image"
        class="background-teaser__image"
        :width="570"
        :height="570"
        :mobile-width="374"
        :mobile-height="374"
        mode="crop"
        :lazy="true"
      />
      <div class="background-teaser__content">
        <p v-if="article.attributes.teaser.preTitle" class="background-teaser__category">
          {{ article.attributes.teaser.preTitle }}
        </p>
        <p v-else-if="article.relationships.channels" class="background-teaser__category">
          {{ article.relationships.channels | getChannelName($config) }}
        </p>
        <SubHeadline v-if="article.attributes.teaser.title" tag="h2" class="background-teaser__headline">
          <ArticleLink
            v-snip="4"
            :article="article"
            :title="article.attributes.teaser.title"
            class="background-teaser__link"
          >
            {{ article.attributes.teaser.title }}
          </ArticleLink>
        </SubHeadline>
        <footer class="background-teaser__footer">
          <template v-if="article.relationships.authors.length > 0">
            <span
              v-for="(author, index) in article.relationships.authors"
              :key="index"
              class="background-teaser__authors"
            >
              {{ author.attributes.firstName }} {{ author.attributes.lastName }}
              <span
                v-if="article.relationships.authors.length === 2 && index + 1 !== article.relationships.authors.length"
                :key="index"
              >
                &nbsp;und&nbsp;
              </span>
              <span
                v-if="
                  article.relationships.authors.length > 2 && index + 1 === article.relationships.authors.length - 1
                "
                :key="index"
              >
                &nbsp;und&nbsp;
              </span>
              <span
                v-if="
                  article.relationships.authors.length > 2 &&
                  index + 1 !== article.relationships.authors.length &&
                  index + 1 !== article.relationships.authors.length - 1
                "
                :key="index"
              >
                ,&nbsp;
              </span>
            </span>
          </template>
          <template v-if="article.relationships.authors.length > 0 && article.attributes.frontendDate"> ﹒ </template>
          <template v-if="article.attributes.frontendDate">
            {{ article.attributes.frontendDate | formatDate($config) }}
          </template>
        </footer>
      </div>
    </div>
  </article>
</template>

<script>
import SpunqImage from '@/components/ui/image/spunq-image'
import SubHeadline from '@/components/ui/text/subheadline'
import ArticleLink from '@/components/article/link'

export default {
  components: {
    SpunqImage,
    SubHeadline,
    ArticleLink,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.background-teaser {
  width: 100%;
  position: relative;
  overflow: hidden;

  &-box {
    overflow: hidden;
    position: relative;
    background-color: $color-gray-background;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;

    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  &:hover {
    .background-teaser__image img {
      transform: scale(1.01);
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 15px;

    @media all and (min-width: $screen-width-767) {
      left: 13px;
      right: 13px;
    }
  }

  &__category {
    font-size: 13px;
    color: $color-white;
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    margin: 0 0 10px 0;
    width: 100%;
    text-align: center;

    @media all and (min-width: $screen-width-767) {
      font-size: 12px;
    }
  }

  &__headline {
    font-family: $georgia;
    font-size: 32px;
    line-height: 40px;
    margin-top: 0;
    width: 100%;
    text-align: center;

    @media all and (min-width: $screen-width-767) {
      font-size: 36px;
      line-height: 44px;
    }
  }

  &__link {
    color: $color-white;

    &:after {
      content: '/a';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0;
    }
  }

  &__footer {
    color: $color-white;
    font-size: 13px;
    line-height: 130%;
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    margin-bottom: 20px;
    text-align: center;

    @media all and (min-width: $screen-width-767) {
      font-size: 12px;
    }
  }
}
</style>
