<template>
  <article
    :class="[{ darkmode: darkmode }, { 'hero-teaser--dossier': dossier }, 'hero-teaser']"
    :title="article.attributes.teaser.title"
  >
    <SpunqImage
      v-if="article.attributes.teaser.image"
      :image="article.attributes.teaser.image"
      :width="1440"
      :height="720"
      :mobile-width="768"
      :mobile-height="768"
      mode="crop"
      class="hero-teaser__image"
      :lazy="false"
    />
    <div v-if="!article.attributes.teaser.image && dossier" class="hero-teaser__no-image" />
    <div class="hero-teaser__body" :class="[{ 'hero-teaser__body--dossier': dossier }, 'hero-teaser__body']">
      <div v-if="article.type === 'Dossier'" class="hero-teaser__dossierhead">
        <div class="hero-teaser__tags">
          <StickerFocus v-if="isFocus" />
          <StickerDiscourse v-else />
          <span v-if="article.attributes.hashTag" class="hero-teaser__pretitle">
            <ChannelLink :channels="article.relationships.channels"> #{{ article.attributes.hashTag }} </ChannelLink>
          </span>
        </div>
        <h2 v-snip="3" class="hero-teaser__title">
          {{ article.attributes.teaser.title }}
        </h2>
      </div>
      <div class="hero-teaser__content">
        <div :class="[{ 'texts--dossier': dossier }, 'texts']">
          <template v-if="article.type !== 'Dossier'">
            <span v-if="article.attributes.teaser.preTitle" class="hero-teaser__pretitle">
              {{ article.attributes.teaser.preTitle }}
            </span>
            <span v-else-if="article.relationships.channels" class="hero-teaser__pretitle">
              {{ article.relationships.channels | getChannelName($config) }}
            </span>
            <h2 :class="[{ 'hero-teaser__title--article': !dossier }, 'hero-teaser__title']">
              <ArticleLink
                v-if="!dossier"
                v-snip="3"
                :article="article"
                :class="[{ 'hero-teaser__link--article': !dossier }, 'hero-teaser__link teaser-link']"
              >
                {{ article.attributes.teaser.title }}
              </ArticleLink>
              <p v-else v-snip="3">
                {{ article.attributes.teaser.title }}
              </p>
            </h2>
          </template>
          <TimelineVisual v-if="!dossier" :date="article.attributes.frontendDate" />
          <p
            v-if="article.attributes.teaser.leadText"
            :class="[{ 'hero-teaser__leadtext--article': !dossier }, 'hero-teaser__leadtext']"
          >
            {{ article.attributes.teaser.leadText }}
          </p>
          <p v-if="article.attributes.isFocus && article.attributes.frontendDate" class="hero-teaser__date">
            {{ article.attributes.frontendDate | formatDate($config) }}
          </p>
          <Redaction
            v-if="article.relationships.authors.length > 0 && article.attributes.isFocus"
            :authors="article.relationships.authors"
            :article-type="article.type"
          />
          <ShareBar
            :share-title="article.attributes.teaser.title"
            :share-on="['facebook', 'twitter', 'mailto']"
            :darkmode="darkmode"
            :class="[{ 'sharebar--darkmode': !dossier }, 'hero-teaser__sharebar']"
          />
        </div>
        <TeaserSliderHero
          v-if="dossier && article.relationships.articles && article.relationships.articles.length > 0"
          class="dossier-header__slider"
          :article="article"
        />
      </div>
    </div>
  </article>
</template>

<script>
import Moment from 'moment'
import SpunqImage from '@/components/ui/image/spunq-image'
import ShareBar from '@/components/ui/socialmedia/sharing'
import Redaction from '@/components/ui/author/redaction'
import ArticleLink from '@/components/article/link'
import ChannelLink from '@/components/channel/link'
import TimelineVisual from '@/components/ui/timeline/timeline-visual'
import TeaserSliderHero from '@/components/ui/slider/teaser-slider-hero'
import StickerFocus from '@/components/ui/tag/sticker-focus'
import StickerDiscourse from '@/components/ui/tag/sticker-discourse'

export default {
  components: {
    StickerDiscourse,
    StickerFocus,
    SpunqImage,
    ShareBar,
    Redaction,
    ArticleLink,
    ChannelLink,
    TimelineVisual,
    TeaserSliderHero,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    dossier: {
      type: Boolean,
      required: false,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    darkmode() {
      return this.article.attributes.background === 1
    },
    isFocus() {
      return this.article.attributes.isFocus
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.hero-teaser {
  position: relative;
  flex-shrink: 0; // Fix for IE11 for proper image resizing
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;

  @media screen and (min-width: $screen-width-767) {
    min-height: 640px;
    flex-shrink: 1;
  }

  &__tags {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    & > * {
      margin-right: 20px;
    }
  }

  &__pretitle {
    color: $color-turquoise;
    text-transform: capitalize;
    font-size: 16px;
    display: block;
    font-weight: 700;
    font-variation-settings: 'wght' 700;

    a {
      &:hover,
      &:active,
      &:focus {
        color: lighten($color-turquoise, 10%);
        transition-duration: 0.3s;
      }
    }

    @media screen and (min-width: $screen-width-767) {
      font-size: 16px;
    }
  }

  &__tag {
    margin-bottom: 0.5rem;
  }

  &__image {
    width: 100%;
    left: -100%;
    right: -100%;
    margin: auto;
    position: absolute;
    top: 0;
    width: 768px;
    height: 768px;

    @media screen and (min-width: $screen-width-767) {
      width: 1440px;
      height: 720px;
    }

    @media screen and (min-width: $screen-width-1440) {
      width: 100%;
    }

    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__no-image {
    background: $color-black;
    padding-bottom: 169%;
    @media screen and (min-width: $screen-width-767) {
      padding-bottom: 50%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: $screen-width-767) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__dossierhead {
    padding-left: 20px;
    padding-top: 20px;
    width: 100%;

    @media screen and (min-width: $screen-width-1300) {
      padding-left: 100px;
      padding-top: 0;
    }
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 1;
    padding-top: 4rem;
    padding-bottom: 1.5rem;

    @media screen and (min-width: $screen-width-767) {
      padding-top: 70px;
      padding-bottom: 0;
      margin-top: 30px;
    }

    @media screen and (min-width: $screen-width-1023) {
      padding-top: 120px;
    }

    // &--dossier {
    //   flex-direction: column;
    //   @media screen and (min-width: $screen-width-767) {
    //     flex-direction: row;
    //     align-items: center;
    //   }
    // }

    .texts {
      padding: 20px;
      width: 100%;

      @media screen and (min-width: $screen-width-767) {
        padding: 0 10px 1rem 20px;
      }

      @media screen and (min-width: $screen-width-1300) {
        padding: 0 10px 1rem 100px;
      }

      &--dossier {
        width: 100%;

        @media screen and (min-width: $screen-width-767) {
          width: 500px;
          flex-shrink: 0;
        }
      }

      .tag {
        padding: 5px 0;
        color: $color-turquoise;
      }

      .author {
        display: block;
        text-align: left;

        a {
          display: block;
          text-align: left;
        }
      }
    }
  }

  &__title {
    font-size: 32px;
    line-height: 34px;
    margin-bottom: 1rem;
    color: $color-text-dark-gray;

    @media screen and (min-width: $screen-width-767) {
      margin-bottom: 2.5rem;
      max-width: 80%;
      line-height: 112%;
      font-size: 50px;
    }
    @media screen and (min-width: 1441px) {
      font-size: 50px;
    }
  }

  &__link {
    color: $color-text-dark-gray;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  &__leadtext {
    display: block;
    margin-bottom: 10px;
    font-size: 17px;
    line-height: 25px;
    font-variation-settings: 'wght' 600;
    font-weight: 600;
    color: $color-text-dark-gray;

    @media screen and (min-width: $screen-width-767) {
      max-width: 470px;
      margin-bottom: 30px;
    }
  }

  &__sharebar {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 3;

    @media screen and (min-width: $screen-width-767) {
      margin-bottom: 1rem;
      max-width: 470px;
    }
    &.sharebar {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__date {
    font-size: 14px;
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    color: $color-text-dark-gray;
  }

  &:hover {
    .hero-teaser__image {
      img {
        transform: scale(1.01);
      }
    }
  }

  &--dossier {
    height: 768px;
    @media screen and (min-width: $screen-width-767) {
      height: 720px;
    }
    &:hover {
      .hero-teaser__image {
        img {
          transform: scale(1);
        }
      }
    }
    .hero-teaser__image {
      &:after {
        display: none;
      }
    }
    .hero-teaser__leadtext {
      @media screen and (min-width: $screen-width-767) {
        font-variation-settings: 'wght' 400;
      }
    }
  }

  .timeline-visual {
    transform-origin: left center;
    margin-left: 12px;
    .timeline__lines {
      span {
        color: $color-white;
      }
      i {
        background: $color-white;
      }
    }
  }

  .author__and,
  .author__from {
    color: $color-text-dark-gray;
  }

  .default-author {
    padding-bottom: 20px;
    @media screen and (min-width: $screen-width-767) {
      padding-bottom: 40px;
    }
    > span {
      display: inline-flex;
    }
  }

  .dossier-header__slider {
    .swiper-wrapper {
      opacity: 0;
      transition: 0.5s;
    }

    .swiper-container-initialized {
      .swiper-wrapper {
        opacity: 1;
      }
    }
  }
}

.darkmode .hero-teaser {
  &__link,
  &__leadtext,
  &__title,
  &__date {
    color: $color-white;
  }
}
.hero-teaser {
  .hero-teaser__title {
    &.hero-teaser__title--article {
      color: $color-white;
    }
  }
  .hero-teaser__leadtext {
    &.hero-teaser__leadtext--article {
      color: $color-white;
    }
  }
  .hero-teaser__link {
    &.hero-teaser__link--article {
      color: $color-white;
    }
  }
}
</style>
